import React, { useState, useEffect } from "react";
import { auth, db } from "../config/firebase";
import { useDispatch } from "react-redux";
import { handleModel } from "../redux/layoutSlices/modelSlice";
import { classSpace, getOrganization, navLink, organization, redirect_uris, space } from "../constants";
import IlmPortalNavbar from "../components/ilm-nav/IlimiyaNavigation.jsx";
import { useSelector } from "react-redux";
import { logout } from "../helpers/logout.js";
import { ApiRequests } from "../service/ApiRequests.js";
import useAnalytics from "../hooks/firebase/page-view.jsx";
import { getAuth } from "firebase/auth";
import { onChildAdded, ref, update } from "firebase/database";
import { toast } from "react-toastify";

const HeaderLayout = ({ children }) => {
  useAnalytics();

  const customToken = localStorage.getItem("custom-token");

  const d = useDispatch();
  const modelState = useSelector((s) => s?.model?.modelState?.LeftSidebarBase);
  const { currentUser: user } = getAuth();
  const [orgsName, setOrgsName] = useState("");
  const [loading, setLoading] = useState(false);
  console.log("🚀 ~ HeaderLayout ~ orgsName:", orgsName);
  let pendingNotifications = []; // To track multiple pending notifications
  let isToastActive = false; // To prevent showing multiple toasts

  const [orgs, setOrgs] = useState({
    limit: 10,
    page: 1,
    results: [],
    totalPages: 1,
    totalResults: 1
  })
  const fetchOrgs = async (params) => {
    const res = await ApiRequests.getOrganizationsMembership({
      ...params,
      ...(orgsName ? { name: orgsName } : {})
    })
    setOrgs(res.data)
  }
  useEffect(() => {
    fetchOrgs({});
  }, [orgsName]);

  const listenForNewNotifications = () => {
    const notificationsRef = ref(db, `notifications/${organization()?.tenantId}/${user?.uid}`);

    onChildAdded(notificationsRef, (snapshot) => {
      const newNotification = snapshot.val();
      const notificationId = snapshot.key;
      if (newNotification?.notify === false || !newNotification?.notify) {
        pendingNotifications.push({
          id: notificationId,
          message: newNotification.description,
        });
        if (pendingNotifications.length > 0 && !isToastActive) {
          isToastActive = true;
          toast.info(
            pendingNotifications.length === 1
              ? pendingNotifications[0].message
              : `You have ${pendingNotifications.length} new notifications`,
            {
              onClose: () => {
                pendingNotifications.forEach(({ id }) =>
                  markNotificationAsNotified(id)
                );
                pendingNotifications = [];
                isToastActive = false;
              },
            }
          );
        }
      }
    });
  };

  const markNotificationAsNotified = async (notificationId) => {
    try {
      const notificationRef = ref(
        db,
        `notifications/${organization()?.tenantId}/${user?.uid}/${notificationId}`
      );
      await update(notificationRef, { notify: true });
      console.log(`Notification ${notificationId} marked as notified.`);
    } catch (error) {
      console.error("Error marking notification as notified:", error);
    }
  };

  useEffect(() => {
    listenForNewNotifications();
  }, []);

  const toggleMenu = () => {
    // d(
    //   handleModel({
    //     model: "LeftSidebarBase",
    //     state: !modelState,
    //     args: { resource: "leftSidebar" },
    //   })
    // );
  }
  return (
    <>
      <IlmPortalNavbar
        onPortalChange={(e) => {
          const url =
            redirect_uris[e] +
            `?token=${customToken}&space_id=${classSpace().class_id}`;
          window.open(url, "_blank");
        }}
        sidebar={false}
        onSwitchOrg={() => {
          window.open(redirect_uris.Login + "organizations", "_blank");
        }}
        onChangeOrgName={(e) => {
          setOrgsName(e)
        }}
        onChangeOrg={(org) => {
          const newUrl = window.location.href.replace(space, org.displayName);
          window.open(newUrl, "_blank");
        }}
        sidebarUris={navLink}
        orgs={orgs.results}
        auth={auth}
        urls={navLink}
        // logout={logout}
        logout={() => logout(setLoading)}
        toggleMenu={toggleMenu}
        menuState
        switchPopup
        loading={loading}
      >
        {children}
      </IlmPortalNavbar>
    </>
  )
}
export default HeaderLayout
