import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { RiMenu2Fill } from "react-icons/ri";
import { RiBarChartBoxLine } from "react-icons/ri";
import { FaCircle, FaStar } from "react-icons/fa";
import { TbUserSquare } from "react-icons/tb";
import { CgNotes } from "react-icons/cg";
import { MdLogout } from "react-icons/md";
import { MdOutlineDashboard } from "react-icons/md";
import { MdOutlineScreenSearchDesktop } from "react-icons/md";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { BsFileEarmarkText } from "react-icons/bs";
import { CiCirclePlus } from "react-icons/ci";
import { BsArrowClockwise } from "react-icons/bs";
import { TbUserPlus } from "react-icons/tb";
import { MdKeyboardArrowRight } from "react-icons/md";
import { TbGridDots } from "react-icons/tb";
import { RiInboxLine } from "react-icons/ri";
import { FaRegLifeRing } from "react-icons/fa";
import "./Analytics.scss";
import { BiBarChartAlt2 } from "react-icons/bi";
import startIcon from "../../images/inbox.svg";
import avatar from "../../assets/images/avatars/avatar.png";
import analytics from "../../images/Analytics.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { redirect_uris } from "../../constants";
import { getAuth } from "firebase/auth";
import { organization } from "../../constants";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Navbar from "../NewNavbar/Navbar";
import SwitchSpaces from "./switchSpaces";
import { FiMenu } from "react-icons/fi";
import { IoNotifications } from "react-icons/io5";
import home from "../../images/team_dashboard.svg";
import star from "../../images/star.svg";
import avtar from "../../images/image 2575.png";
import people from "../../images/People.svg";
import collab from "../../images/club.svg";
import engage from "../../images/engage.svg";
import { MdOutlineSettings } from "react-icons/md";
import { FaRegUser } from "react-icons/fa6";
import { LuLogOut } from "react-icons/lu";
import { FiLayout } from "react-icons/fi";
import { MdEventNote } from "react-icons/md";
import { MdOutlineAnalytics } from "react-icons/md";
import { Spinner } from "react-bootstrap";
import PermIdentitySharpIcon from "@mui/icons-material/PermIdentitySharp";
import { countUserEvent, toTitleCase } from "../../utils/utlis";
import help from "../../images/header.svg";
import { useDispatch, useSelector } from "react-redux";
import { CiLink } from "react-icons/ci";
import { getMyFavouriteRoutesAsyncThunk } from "../../redux/pagesSlices/favouriteSlice";
import {
  get,
  onChildAdded,
  onValue,
  push,
  ref,
  remove,
  set,
  update,
} from "firebase/database";
import { db } from "../../config/firebase";
import { toast } from "react-toastify";

const IlmPortalNavbar = ({
  onPortalChange,
  auth,
  logout,
  loading,
  sidebar = true,
  menuState = false,
  onSwitchOrg = () => {},
  sidebarUris = [],
  children = null
}) => {
  const { favouriteRoutes, loadings } = useSelector((state) => state.favourite);
  const { currentUser: user } = getAuth();
  const [sidebarClass, setSidebarClass] = React.useState("");
  const [sidebarClassMobile, setSidebarClassMobile] = React.useState("");
  const dispatch = useDispatch();
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [notifications, setNotifications] = useState({
    notifications: [],
    notificationsArray: [],
  });
  const dropdownRef = useRef(null);
  const userRole = localStorage.getItem("role");

  const getNotifications = async () => {
    const allNotifications = await get(
      ref(db, `notifications/${organization()?.tenantId}/${user?.uid}`)
    );
    if (allNotifications.val()) {
      const notificationValues = Object.values(allNotifications.val());

      const filteredNotifications = notificationValues.filter(
        (notification) =>
          notification.status === "unread" || !notification.status
      );
      const sortedNotifications = filteredNotifications.sort(
        (a, b) => b.timestamp - a.timestamp
      );

      const notificationIds = Object.keys(allNotifications.val());

      setNotifications({
        notifications: sortedNotifications,
        notificationsArray: notificationIds,
      });
    }
  };

  useEffect(() => {
    getNotifications();
    dispatch(getMyFavouriteRoutesAsyncThunk({}));
  }, []);

  const toggleHideClass = () => {
    setSidebarClass((prevClass) =>
      prevClass.includes('active') ? '' : 'active'
    )
  }
  const addActiveClass = () => {
    setSidebarClassMobile('show')
  }
  const removeActiveClass = () => {
    setSidebarClassMobile('')
  }

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index)
  }

  const handleAnchorClick = (index, event) => {
    event.stopPropagation() // Prevent the click event from propagating to the document body
    toggleDropdown(index)
  }

  const handleBodyClick = () => {
    setActiveDropdown(null) // Remove active class when clicking on the body
  }

  const profileHandler = () => {
    window.open(redirect_uris.Login + "profile/" + user.uid, "_blank");
  };

  const accountHandler = () => {
    window.open(redirect_uris.Login, '_blank')
  }

  document.body.addEventListener('click', handleBodyClick)
  const customToken = localStorage.getItem('custom-token')

  const [orgData, setOrgData] = useState({})
  useEffect(() => {
    const data = organization()
    setOrgData(data)
  }, [])

  const formatNotificationType = (str) => {
    if (str)
      return str
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    else return str;
  };

  const markNotificationAsRead = async (notificationId) => {
    try {
      const notificationRef = ref(
        db,
        `notifications/${organization()?.tenantId}/${
          user?.uid
        }/${notificationId}`
      );
      await update(notificationRef, { notify: true, status: "read" }).then(
        () => {
          getNotifications();
        }
      );
      console.log(`Notification ${notificationId} marked as notified.`);
    } catch (error) {
      console.error("Error marking notification as notified:", error);
    }
  };
  return (
    <>
      {/* <Navbar/>  */}
      <div className="newnav">
        <div className="row d-flex align-items-center justify-content-between">
          <div className="col-md-6 col-5 align-self-center">
            <div className="main-section">
              <div className="section-1">
                <div className="menu-icon">
                  <Dropdown>
                    <Dropdown.Toggle id='dropdown-basic'>
                      <FiMenu />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className='menuItems'>
                        <div className='heading-wrapper'>
                          <strong>
                            {' '}
                            {document.location.pathname
                              .split('/')[1]
                              .charAt(0)
                              .toUpperCase() +
                              document.location.pathname.split('/')[1].slice(1)}
                          </strong>
                        </div>
                        <div className='mainItems'>
                          <div
                            className='items'
                            onClick={() =>
                              onPortalChange &&
                              typeof onPortalChange === 'function' &&
                              onPortalChange('Console')}
                          >
                            <div className='icons'>
                              <FiLayout />
                            </div>
                            <p>Home</p>
                          </div>

                          <div
                            className="items"
                            // onClick={() =>

                            //   onPortalChange &&
                            //   typeof onPortalChange === "function" &&
                            //   onPortalChange("People")
                            // }

                            onClick={() => {
                              countUserEvent("people", {
                                eventName: "navigate_console_people",
                              });
                              if (
                                onPortalChange &&
                                typeof onPortalChange === "function"
                              ) {
                                onPortalChange("People");
                              }
                            }}
                          >
                            <div className='icons'>
                              <TbUserSquare />
                            </div>
                            <p>People</p>
                          </div>

                          {/* <div
                            className="items"
                            onClick={() =>
                              onPortalChange &&
                              typeof onPortalChange === "function" &&
                              onPortalChange("Admin")
                            }
                          >
                            <img src={collab} alt="HomeIcon" />
                            <p>Collab</p>
                          </div> */}

                          <div
                            className="items"
                            // onClick={() =>
                            //   onPortalChange &&
                            //   typeof onPortalChange === "function" &&
                            //   onPortalChange("Studio")
                            // }

                            onClick={() => {
                              countUserEvent("engage", {
                                eventName: "navigate_console_engage",
                              });
                              if (
                                onPortalChange &&
                                typeof onPortalChange === "function"
                              ) {
                                onPortalChange("Studio");
                              }
                            }}
                          >
                            <div className='icons'>
                              <MdEventNote />
                            </div>
                            <p>Engage</p>
                          </div>

                          <div
                            className="items"
                            // onClick={() =>
                            //   onPortalChange &&
                            //   typeof onPortalChange === "function" &&
                            //   onPortalChange("Analytics")
                            // }

                            onClick={() => {
                              countUserEvent("engage", {
                                eventName: "navigate_console_engage",
                              });
                              if (
                                onPortalChange &&
                                typeof onPortalChange === "function"
                              ) {
                                onPortalChange("Analytics");
                              }
                            }}
                          >
                            <div className='icons'>
                              <MdOutlineAnalytics />
                            </div>
                            <p>Analytics</p>
                          </div>
                          {/* {userRole === "Admin" && (
                            <div
                              className="items"
                              onClick={() => {
                                // Call countUserEvent for "logout" event
                                countUserEvent("admin", {
                                  eventName: "navigate_console_admin",
                                });

                                // Proceed with onPortalChange logic
                                if (
                                  onPortalChange &&
                                  typeof onPortalChange === "function"
                                ) {
                                  onPortalChange("Admin");
                                }
                              }}
                            >
                              <div className="icons">
                                <MdOutlineAdminPanelSettings />
                              </div>
                              <p>Admin</p>
                            </div>
                          )} */}
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <SwitchSpaces />
            </div>
          </div>
          <div className="col-md-6 col-7 align-self-center">
            <div className="icons-section">
              {/* <Dropdown direction="end">
                <Dropdown.Toggle id="dropdown-basic">
                  <div className="starIcon">
                    <FaStar fontSize={20} />
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-end dropdown-menu-avtar">
                  <div className="menuItems">
                    {loadings?.getMyFavouriteRoutesAsyncThunk ? (
                      <Spinner animation="border" variant="primary" />
                    ) : (
                      <>
                        <div className="dropdown-heading">
                          <strong>Favourite Routes</strong>
                        </div>
                        <div className="mainItems">
                          {favouriteRoutes?.map((item, i) => {
                            return (
                              <div
                                key={i}
                                className="items"
                                onClick={() => {
                                  window.open(item.route_url, "_blank");
                                }}
                              >
                                <div className="icons-1">
                                  <CiLink />
                                </div>

                                <p>{toTitleCase(item.route_name)}</p>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                </Dropdown.Menu>
              </Dropdown> */}

              <Dropdown direction="end">
                <Dropdown.Toggle id="dropdown-basic">
                  <div className="starIcon">
                    <IoNotifications fontSize={20} />
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-end dropdown-menu-avtar">
                  <div className="menuItems">
                    {loadings?.getMyFavouriteRoutesAsyncThunk ? (
                      <Spinner animation="border" variant="primary" />
                    ) : (
                      <>
                        <div className="dropdown-heading">
                          <strong>Notifications</strong>
                        </div>
                        <div className="notificationItmes">
                          {notifications?.notifications?.map((item, i) => {
                            return (
                              <div
                                key={i}
                                className="items"
                                onClick={() =>
                                  markNotificationAsRead(
                                    notifications.notificationsArray[i]
                                  )
                                }
                                // onClick={() => {
                                //   countUserEvent("admin", {
                                //     eventName: "navigate_console_admin",
                                //   });

                                //   if (
                                //     onPortalChange &&
                                //     typeof onPortalChange === "function"
                                //   ) {
                                //     onPortalChange("Admin");
                                //   }
                                // }}
                              >
                                <p>
                                  <b>{formatNotificationType(item?.type)}</b>
                                </p>
                                <p>{item.description}</p>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <div className="starIcon">
                <a href="https://support.ilmiya.com/hc/en-us">
                  <img src={help} alt="star" />
                </a>
              </div>
              <div className='avtar'>
                <Dropdown direction='end'>
                  <Dropdown.Toggle id='dropdown-basic'>
                    <img
                      style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50px'
                      }}
                      src={user?.photoURL || avatar}
                      alt='Avtar'
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className='dropdown-menu-end dropdown-menu-avtar'>
                    <div className='menuItems'>
                      {loading
                        ? (
                          <Spinner animation='border' variant='primary' />
                          )
                        : (
                          <div className='dropdown-heading'>
                            <img
                              style={{
                                width: '36px',
                                height: '36px',
                                borderRadius: '50px'
                              }}
                              src={user?.photoURL || avatar}
                              alt='Avtar'
                            />
                            <strong>{auth?.currentUser?.displayName}</strong>
                          </div>
                          )}

                      <div className="mainItems">
                        <div
                          className="items"
                          onClick={() => {
                            countUserEvent("profile", {
                              eventName: "navigate_console_profile",
                            });
                            profileHandler();
                          }}
                        >
                          <div className="icons-1">
                            <PermIdentitySharpIcon />
                          </div>

                          <p>Profile</p>
                        </div>

                        <div
                          className="items"
                          onClick={() => {
                            countUserEvent("account", {
                              eventName: "navigate_console_account",
                            });
                            accountHandler();
                          }}
                        >
                          <div className="icons">
                            {/* <img src={settings} alt="HomeIcon" /> */}
                            <MdOutlineSettings />
                          </div>

                          <p>Accounts</p>
                        </div>

                        {userRole === "Admin" && (
                          <div
                            className="items"
                            onClick={() => {
                              // Call countUserEvent for "logout" event
                              countUserEvent("admin", {
                                eventName: "navigate_console_admin",
                              });

                              // Proceed with onPortalChange logic
                              if (
                                onPortalChange &&
                                typeof onPortalChange === "function"
                              ) {
                                onPortalChange("Admin");
                              }
                            }}
                          >
                            <div className='icons'>
                              <MdOutlineAdminPanelSettings />
                            </div>
                            <p>Admin</p>
                          </div>
                        )}
                      </div>
                      <div className='logout-btn'>
                        <div className='icons'>
                          <LuLogOut />
                        </div>

                        <p
                          // onClick={logout}
                          onClick={() => {
                            countUserEvent("logout", {
                              eventName: "create_ilmiya_console_account",
                            });
                            logout();
                          }}
                        >
                          Logout
                        </p>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>

                <div className='dot-icon'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='9'
                    height='9'
                    viewBox='0 0 9 9'
                    fill='none'
                  >
                    <rect
                      x='0.902344'
                      y='0.902344'
                      width='7.2'
                      height='7.2'
                      rx='3.6'
                      fill='#00AB1B'
                      stroke='white'
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='allStudentsStreamPage'>
        {/* <header className="analytics-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4 col-5 align-self-center d-flex gap-3">
                <div className="logo-wrapper">
                  <a href="#" onClick={(event) => handleAnchorClick(2, event)}>
                    <img
                      src={orgData?.branding?.logo_url || require("./logo.png")}
                      alt="logo-icon"
                    />
                    <strong>{orgData?.displayName}</strong>
                  </a>
                  {menuState ? (
                    <div
                      className={`dropdown-div ${
                        activeDropdown === 2 ? "active" : ""
                      } `}
                    >
                      <div className="title">
                        <a href="#">
                          <img src={require("./logo.png")} alt="logo-icon" />
                          <strong>ILMIYA</strong>
                        </a>
                      </div>
                      <ul className="list">
                        <li>
                          <a
                            href={
                              redirect_uris.People +
                              `staff/?token=${customToken}`
                            }
                          >
                            <TbUserPlus />
                            <span>Invite a member</span>
                          </a>
                        </li>
                      </ul>
                      <ul className="list">
                        <li onClick={onSwitchOrg}>
                          <a href="#">
                            <BsArrowClockwise />
                            <span className="more-icon">
                              Switch Space <MdKeyboardArrowRight />{" "}
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  ) : null}
                </div>
                <SwitchSpaces />
              </div>
              <div className="col-md-8 col-7 align-self-center">
                <div className="header-menu">
                  <ul className="icons-wrapper">
                    <li>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip
                            id="tooltip-top"
                            style={{ position: "fixed" }}
                          >
                            Pages
                          </Tooltip>
                        }
                      >
                        <a
                          href="#"
                          className={activeDropdown === 0 ? "active" : ""}
                          onClick={(event) => handleAnchorClick(0, event)}
                        >
                          <TbGridDots />
                        </a>
                      </OverlayTrigger>
                      <div
                        className={`dropdown-div  ${
                          activeDropdown === 0 ? "active" : ""
                        } `}
                      >
                        <ul className="page-list">
                          <li
                            onClick={() =>
                              onPortalChange &&
                              typeof onPortalChange === "function" &&
                              onPortalChange("Console")
                            }
                          >
                            <a href="#">
                              <div className="icon-wrapper">
                                <MdOutlineDashboard />
                              </div>
                              <span>Dashboard</span>
                            </a>
                          </li>
                          <li
                            onClick={() =>
                              onPortalChange &&
                              typeof onPortalChange === "function" &&
                              onPortalChange("People")
                            }
                          >
                            <a href="#">
                              <div className="icon-wrapper">
                                <TbUserSquare />
                              </div>
                              <span>People</span>
                            </a>
                          </li>
                          <li
                            onClick={() =>
                              onPortalChange &&
                              typeof onPortalChange === "function" &&
                              onPortalChange("Studio")
                            }
                          >
                            <a href="#">
                              <div className="icon-wrapper">
                                <MdOutlineScreenSearchDesktop />
                              </div>
                              <span>Engage</span>
                            </a>
                          </li>
                          <li
                            onClick={() =>
                              onPortalChange &&
                              typeof onPortalChange === "function" &&
                              onPortalChange("Analytics")
                            }
                          >
                            <a href="#">
                              <div className="icon-wrapper">
                                <RiBarChartBoxLine />
                              </div>
                              <span>Analytics</span>
                            </a>
                          </li>
                          <li
                            onClick={() =>
                              onPortalChange &&
                              typeof onPortalChange === "function" &&
                              onPortalChange("Admin")
                            }
                          >
                            <a href="#">
                              <div className="icon-wrapper">
                                <MdOutlineAdminPanelSettings />
                              </div>
                              <span>Admin</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <ul className="icon-list">
                        <li>
                          <div className="nav-item">
                            <div className="nav-link">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="link"
                                  id="dropdown-basic"
                                >
                                  <img
                                    src={startIcon}
                                    style={{ height: 20, width: 20 }}
                                  />
                                </Dropdown.Toggle>
                              </Dropdown>
                            </div>
                          </div>
                        </li>
                        <li className="avatar">
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip
                                id="tooltip-top"
                                style={{ position: "fixed" }}
                              >
                                Profile
                              </Tooltip>
                            }
                          >
                            <a href="#">
                              <img
                                src={user?.photoURL || avatar}
                                alt="avatar"
                                className={`avatar ${
                                  activeDropdown === 1 ? "active" : ""
                                } `}
                                onClick={(event) => handleAnchorClick(1, event)}
                              />
                            </a>
                          </OverlayTrigger>
                          <div
                            className={`dropdown-div profile ${
                              activeDropdown === 1 ? "active" : ""
                            } `}
                          >
                            <div className="img-wrapper">
                              <img
                                src={user?.photoURL || avatar}
                                alt="avatar"
                              />
                            </div>
                            <div className="content-wrapper">
                              <p className="name">
                                Hello, {auth?.currentUser?.displayName}
                              </p>
                              <span className="span">
                                <a href="#">Ilmiya</a>
                              </span>
                              <ul className="list">
                                <li onClick={profileHandler}>
                                  <a href="#">
                                    <TbUserSquare />
                                    <span>Profile</span>
                                  </a>
                                </li>
                                <li>
                                  <a href={redirect_uris?.Admin}>
                                    <CgNotes />
                                    <span>Settings</span>
                                  </a>
                                </li>
                                <li onClick={logout}>
                                  <a href="#">
                                    <MdLogout />
                                    <span>Logout</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header> */}
        <div
          id='overLay'
          className={sidebarClassMobile}
          onClick={removeActiveClass}
        />
        {sidebar
          ? (
            <div className='main-dashboard-wrapper'>
              <div
                id='side-bar-wrapper'
                className={`${sidebarClass} ${sidebarClassMobile}`}
              >
                <div className='side-bar-title-wrapper'>
                  <Link to='/'>
                    <img
                      src={require('./analyticsIcon.png')}
                      alt='analyticsIcon'
                    />
                    <strong> {document.location.pathname.split('/')[1]}</strong>
                  </Link>
                </div>
                <div className='sidebar-menu-wrapper'>
                  <ul className='sidebar-menu'>
                    {sidebarUris.map((uri, index) => (
                      <li key={index}>
                        <Link to={uri.path}>
                          {uri.icon}
                          <span className='span'>{uri.name}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className='close-icon-wrapper'>
                  <a
                    href='#'
                    className='sidebar-toggle-close'
                    onClick={toggleHideClass}
                  >
                    <img
                      src={require('./sideBarColorIcon.png')}
                      alt='sideBarColorIcon'
                    />
                  </a>
                </div>
              </div>
              <div id='main-page-body-wrapper' className={sidebarClass}>
                <div className='container-fluid'>{children}</div>
              </div>
            </div>
            )
          : (
              children
            )}
      </div>
    </>
  )
}

export default IlmPortalNavbar
